import { useState } from 'react';
import { Text, Button, HStack, Stack, Table } from '@arcault-ui/shared-components';
import { useSettings } from '../../../context/SettingsContext';
import SecretDialog from './SecretDialog';
import FormDialog from './FormDialog';
import { useSecrets } from './useSecrets';
import './infoAssets.css';

const InfoAssetsTable = ({ addTitle, tableFields, handleFormChangeCallback, onDelete, title, fields, onAdd, onEdit, service, dialogTitle = "" }) => {
  const { settings } = useSettings();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { secret, viewingSecret, editingSecret, handleOpenSecret, handleCloseSecret, handleSecretChange, handleEditSecret, cancelEditSecret, saveSecret } = useSecrets(service, formData, setFormData);


  /*const handleDelete = async (row) => {
    if (onDelete) return onDelete(row);
    try {
      const response = await selectService(service).delete(row);
      if (response.status === 200 || response.status === 201) {
        loadItems();
      } else {
        console.error('Delete failed');
      }
    } catch (error) {
      console.error('Error during Delete', error);
    }
  };

  const handleOpen = (item) => {
    if (handleFormChangeCallback) handleFormChangeCallback(item);
    setFormData(item || {});
    setEditMode(!!item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({});
  };

  const handleSave = async () => {
    try {
      const apiCall = editMode ? selectService(formData.service || service).edit : selectService(formData.service || service).save;
      const response = await apiCall(`${formData.type}/${formData.id}`, formData);
      if (response.status === 200 || response.status === 201) {
        loadItems();
      } else {
        console.error(editMode ? 'Update failed' : 'Create failed');
      }
    } catch (error) {
      console.error(editMode ? 'Error during Update' : 'Error during Create', error);
    }
    handleClose();
  };

  const handleFormChange = (event) => {
    const newFormData = { ...formData, [event.target.name]: event.target.value };
    setFormData(newFormData);
    if (handleFormChangeCallback) handleFormChangeCallback(newFormData);
  };




  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading policies: {error}</p>;

  const columns = (tableFields || fields).map((field) => ({
    id: field.id,
    label: field.label,
    align: field.align || 'left',
  }));

  const actions = [
    { label: 'edit', iconName: 'edit', onClick: handleOpen },
    { label: 'delete', iconName: 'delete', onClick: handleDelete },
    { label: 'secret', iconName: 'key', condition: (row) => !row.hasSecret, iconClassName: 'icon-no-secret', onClick: handleOpenSecret },
    { label: 'hasSecret', iconName: 'key', condition: (row) => row.hasSecret, iconClassName: 'icon-has-secret', onClick: handleOpenSecret },
  ];*/

  return (
    <Stack>
      {/*<HStack variant="spread">
        <Text variant="h3">{title}</Text>
        <Button type="primary" onClick={() => handleOpen()}>{addTitle}</Button>
      </HStack>
      <div>
        <Table columns={columns} rows={rows} totalRow={totalRow} actions={actions} displayValue={displayValue} />
      </div>
      <FormDialog
        open={open}
        handleClose={handleClose}
        editMode={editMode}
        dialogTitle={dialogTitle}
        fields={fields}
        formData={formData}
        handleFormChange={handleFormChange}
        handleSave={handleSave}
      />
      <SecretDialog
        viewingSecret={viewingSecret}
        handleCloseSecret={handleCloseSecret}
        settings={settings}
        secret={secret}
        editingSecret={editingSecret}
        handleSecretChange={handleSecretChange}
        handleEditSecret={handleEditSecret}
        cancelEditSecret={cancelEditSecret}
        saveSecret={saveSecret}
      />*/}
    </Stack>
  );
};

export default InfoAssetsTable;