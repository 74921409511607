import { Route, Routes } from 'react-router-dom';
import Overview from './components/root/Home';
import NotFound from './components/NotFound';
import EstateAdvisor from './components/estate/EstateDashboard';
import InsuranceAdvisor from './components/insurance/Insurance';

import Contacts from './components/contacts/Contacts';
import Documents from './components/documents/Documents';

/* Profile (hopefully to archive) */
import Basics from './components/profile/Basics';
// import Relationship from './components/profile/Relationship';
import Dependants from './components/profile/Dependants';
// import Identification from './components/profile/Identification';
import Care from './components/profile/Care';
import Medicines from './components/profile/Medicines';
import Fitness from './components/profile/Fitness';
import Settings from './components/profile/Settings';
import Assets from './components/assets/Assets';
import Faith from './components/profile/Faith';
import CharitiesComponent from './components/profile/CharitiesComponent';
import Education from './components/profile/Education';
import Favorites from './components/profile/Favorites';
import WorkExperience from './components/profile/WorkExperience';
import MilitaryService from './components/profile/MilitaryService';
import Collections from './components/profile/Collections';
import Digital from './components/profile/Digital';
// import Accountant from './components/profile/Accountant';
import Taxes from './components/profile/Taxes';
import OnlineAccounts from './components/profile/OnlineAccounts';
import Banking from './components/profile/Banking';
import Secrets from './components/profile/Secrets';
import FamilyStructure from './components/profile/FamilyStructure';
// import EstateExecutorForm from './components/profile/EstateExecutorForm';
// import HealthCareProxyForm from './components/profile/HealthCareProxyForm';
// import DurablePowerOfAttorneyForm from './components/profile/DurablePowerOfAttorneyForm';
// import GuardianshipDesignationForm from './components/profile/GuardianshipDesignationForm';
import LivingWillForm from './components/profile/LivingWillForm';
import PCLInsurance from './components/profile/PCLInsurance';
import HealthInsurance from './components/profile/HealthInsurance';
import FamilyTree from './components/profile/FamilyTree';

/* Admin components */
import Creds from './components/admin/Creds'
import JsonEditorPage from './components/admin/JsonEditorPage';

import Terms from './components/auth/Terms'
import Privacy from './components/auth/Privacy'

/* Auth components */
import RequireAuth from './components/auth/RequireAuth';
import OAuthCallback from './components/auth/OAuthCallback';
import Logout from './components/auth/Logout'; // Import your logout component
import SignIn from './components/auth/SignIn'; // Import your logout component
import SignUp from './components/auth/SignUp'; // Import your logout component
import ForgotPassword from './components/auth/ForgotPassword'
import ConfirmEmail from './components/auth/ConfirmEmail'


import Layout from './components/Layout/Layout';
import { useState } from 'react';


function AppRoutes({ signOut, user }) {
  const [basicInfoComplete, setBasicInfoComplete] = useState(false);

  return (
    <Routes>
      {/* public routes */}
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/oauth2/idpresponse" element={<OAuthCallback />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/confirmemail" element={<ConfirmEmail />} />
      {/* authenticated routes */}   
      
      {/* authenticated routes */}   
        <Route element={<RequireAuth />}>
            <Route path="/" element={<Layout user={user} signOut={signOut}/>}>
            <Route path="/" element={basicInfoComplete ? <EstateAdvisor /> : <Overview />} />
            <Route path="/journeys" element={<JsonEditorPage />} />
            <Route path="/creds" element={<Creds />} />
            <Route path="/settings" element={<Creds />} />

            <Route path="/estate" element={<EstateAdvisor />} />
            <Route path="/insurance" element={<InsuranceAdvisor />} />

            <Route path="/contacts" element={<Contacts />} />
            <Route path="/assets" element={<Assets />} />
            <Route path="/documents" element={<Documents />} />

            {/* eager to get rid of */}
            <Route path="/profile" element={<Basics user={user} />} />
            <Route path="/profile/basic" element={<Basics user={user} />} />
            <Route path="/profile/dependants" element={<Dependants user={user} />} />
            <Route path="/profile/family" element={<FamilyStructure />} />
            <Route path="/profile/contacts" element={<Contacts />} />
            <Route path="/profile/care" element={<Care />} />
            <Route path="/profile/medicines" element={<Medicines />} />
            <Route path="/profile/fitness" element={<Fitness />} />
            <Route path="/profile/faith" element={<Faith />} />
            <Route path="/profile/charities" element={<CharitiesComponent />} />
            <Route path="/profile/education" element={<Education />} />
            <Route path="/profile/favorites" element={<Favorites />} />
            <Route path="/profile/work" element={<WorkExperience />} />
            <Route path="/profile/military" element={<MilitaryService />} />
            <Route path="/profile/assets" element={<Assets />} />
            <Route path="/profile/collections" element={<Collections />} />
            <Route path="/profile/digital" element={<Digital />} />
            <Route path="/profile/online" element={<OnlineAccounts />} />
            <Route path="/profile/inspcl" element={<PCLInsurance />} />
            <Route path="/profile/inshealth" element={<HealthInsurance />} />
            <Route path="/profile/estate/livingwill" element={<LivingWillForm />} />
            <Route path="/profile/taxrecords" element={<Taxes />} />
            <Route path="/profile/banking" element={<Banking />} />
            <Route path="/profile/secrets" element={<Secrets />} />
            <Route path="/profile/settings/:section?" element={<Settings />} />
            <Route path="/profile/tree" element={<FamilyTree />} />
            {/* 
            <Route path="/profile/estate/executor" element={<EstateExecutorForm />} />
            <Route path="/profile/estate/healthcareproxy" element={<HealthCareProxyForm />} />
            <Route path="/profile/estate/poa" element={<DurablePowerOfAttorneyForm />} />
            <Route path="/profile/estate/guardianship" element={<GuardianshipDesignationForm />} />
            <Route path="/profile/accountant" element={<Accountant />} />
            <Route path="/profile/relationship" element={<Relationship user={user} />} />
            <Route path="/profile/id" element={<Identification />} />*/}
        </Route>
        </Route>
      {/* authenticated routes */} 
      {/* catch all */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
