import { useState, useEffect } from 'react';
import { FormDialog, TagRow, HStack, Text, Table, Tab, TabList, TabPanel, Card } from '@arcault-ui/shared-components';
import { assetsAPI } from '../../actions/assets';
import { liabilitiesAPI } from '../../actions/liabilities';
import { useSecrets } from './infoAssets/useSecrets';
import { useSettings } from '../../context/SettingsContext';

import './Assets.css';
import SecretDialog from './infoAssets/SecretDialog';

export const assetFields = {
  assetFields: [
    { id: 'assetType', type: 'autocomplete', suggestions: ['House', 'Car', 'Boat', 'Collection', 'Other'], label: 'Asset Type',column: true, form: true},
  ],
  commonFields: [
    { id: 'description', label: 'Description', column: true, form: true, rank: 1, fullWidth: true},
    { id: 'tags', label: 'Tags', type: 'tags', column: true, form: true, rank: 50},
    { id: 'estimatedValue', label: 'Estimated Value',column: true, form: true, rank: 100, align: 'right'},
  ],
  extraFields: {
    Car: [
      { id: 'make', label: 'Make' },
      { id: 'model', label: 'Model' },
      { id: 'year', label: 'Year' }
    ],
    House: [
      { id: 'address', label: 'Address', fullWidth: true},
      { id: 'size', label: 'Size (sqft)' },
      { id: 'lot', label: 'Lot Size (acres)' },
      { id: 'bedrooms', label: 'Bedrooms' },
      { id: 'baths', label: 'Baths' },
    ],
    Collection: [
      { id: 'items', label: 'Items' }
    ],
    financial: [
      { id: 'accountNumber', label: 'Account Number' }
    ],
  }
}


export const handleDynamicFields = (formData, setFields, isAsset, isEdit) => {
  let newFields = [];
  if (isAsset) {
    newFields = [...newFields, ...assetFields.assetFields];
    if (formData?.assetType) newFields = [...newFields, ...assetFields.commonFields, ...assetFields.extraFields[formData?.assetType] || []];
  } else {
    newFields = [...newFields, ...assetFields.commonFields]
  }
  setFields(newFields);
}

const Assets = () => {
  const [fields, setFields] = useState([...assetFields.assetFields, ...assetFields.commonFields]);
  const [activeTab, setActiveTab] = useState('Assets');
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { settings } = useSettings();

  const isAssets = activeTab === 'Assets';


  const loadItems = async () => {
    console.log('loadItems');
    setIsLoading(true);
    const response = await (isAssets ? assetsAPI : liabilitiesAPI).get();
    const dataArray = response.data;
    setRows(dataArray || []);
    setIsLoading(false);
  };


  const {
    secret,
    viewingSecret,
    editingSecret,
    handleOpenSecret,
    handleCloseSecret,
    handleSecretChange,
    handleEditSecret,
    cancelEditSecret,
    saveSecret,
  } = useSecrets({ service: isAssets ? 'assets' : 'liabilities', saveSecretCallback: loadItems });

  useEffect(() => {
    loadItems();
  }, [activeTab]);



  const handleDelete = async (row) => {
    await (isAssets ? assetsAPI : liabilitiesAPI).delete(row);
    loadItems();
  };


  const handleSave = async (formData) => {
    console.log('handleSave', formData);
    try {
      const response = await (isAssets ? assetsAPI : liabilitiesAPI).save(formData);
      
      if (response.status === 200 || response.status === 201) {
        loadItems();
      } else {
        console.error('Create failed');
      }
    } catch (error) {
      console.error('Error during Create', error);
    }
  };

  const handleFormChangeCallback = (formData, isEdit) => {
    handleDynamicFields(formData, setFields, isAssets, isEdit); // need fix
  }

  const totalRow = (id, rows) => {
    switch (id) {
      case "assetType":
        return `${rows.length} items`;
      case "estimatedValue":
        const totalValue = rows.reduce((acc, row) => {
          return acc + (parseInt(row?.estimatedValue?.replace(/[^0-9.-]+/g, ""), 10) || 0)
        }, 0);
        return totalValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      default:
        return '';
    }
  };

  const columns = [...assetFields.assetFields, ...assetFields.commonFields].map((field) => ({
    id: field.id,
    label: field.label,
    align: field.align || 'left',
  }));

  const actions = [
    { label: 'edit', iconName: 'edit', onClick: () => {} },
    { label: 'delete', iconName: 'delete', onClick: handleDelete },
    { label: 'secret', iconName: 'key', condition: (row) => !row.hasSecret, iconClassName: 'icon-no-secret', onClick: handleOpenSecret },
    { label: 'hasSecret', iconName: 'key', condition: (row) => row.hasSecret, iconClassName: 'icon-has-secret', onClick: handleOpenSecret },
  ];

  const addTag = async (tag, contact) => {
    const thisContact = {...rows.find(c => c.id === contact.id)}
    await assetsAPI.addTag(tag, thisContact)
    thisContact.tags = [...thisContact.tags, tag];
    setRows([...rows.map(c => c.id === contact.id ? thisContact : c)])
  }    


  const removeTag = async (tag, contact) => {
    const thisContact = {...rows.find(c => c.id === contact.id)}
    await assetsAPI.removeTag(tag, thisContact)
    thisContact.tags = [...thisContact.tags.filter(t => t !== tag)];
    setRows([...rows.map(c => c.id === contact.id ? thisContact : c)])
  }  

  const displayValue = (value, column, row) => {
    switch (column.id) {
      case "masked":
        return value.toString().length <= 4 ? value : `${'*'.repeat(value.toString().length - 4)}${value.toString().slice(-4)}`;
      case "estimatedValue":
        const intValue = parseInt(value?.replace(/[^0-9.-]+/g, ""), 10);
        return isNaN(intValue) ? '$0.00' : intValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      case "tags":
        return <TagRow tags={value} removeTag={(tag) => removeTag(tag, row)} addTag={(tag) => addTag(tag, row)} />
      default:
        return value;
    }
  };

  return (
    <Card className="card-no-shadow dashboard">
      <TabList>
        <Tab activeTab={activeTab} label="Assets" onClick={setActiveTab}>
          Assets
        </Tab>
        <Tab activeTab={activeTab} label="Liabilities" onClick={setActiveTab}>
          Liabilities
        </Tab>
      </TabList>

      <TabPanel activeTab={activeTab} label="Assets">
        <HStack variant="spread">
          <Text variant="h3">Assets</Text>
          <FormDialog
            buttonText="Add Asset +"
            updateFunction={handleSave}
            modalTitle="Asset"
            fields={fields}
            dataCallback={handleFormChangeCallback}
          />
        </HStack>
        <Table 
          columns={columns}
          isLoading={isLoading}
          rows={rows}
          filters={[{ id: 'tags', placeholder: 'Filter by tag' }]}
          totalRow={totalRow}
          actions={actions}
          displayValue={displayValue}
        />
      </TabPanel>
      <TabPanel activeTab={activeTab} label="Liabilities">
      <HStack variant="spread">
          <Text variant="h3">Liabilities</Text>
          <FormDialog
            buttonText="Add Liability +"
            updateFunction={handleSave}
            modalTitle="Liability"
            fields={fields}
            dataCallback={handleFormChangeCallback}
          />
        </HStack>
        <Table 
          columns={columns}
          isLoading={isLoading}
          rows={rows}
          filters={[{ id: 'tags', placeholder: 'Filter by tag' }]}
          totalRow={totalRow}
          actions={actions}
          displayValue={displayValue}
        />
      </TabPanel>
      <SecretDialog
        viewingSecret={viewingSecret}
        handleCloseSecret={handleCloseSecret}
        settings={settings}
        secret={secret}
        editingSecret={editingSecret}
        handleSecretChange={handleSecretChange}
        handleEditSecret={handleEditSecret}
        cancelEditSecret={cancelEditSecret}
        saveSecret={saveSecret}
      />
    </Card>  
  );
}
export default Assets